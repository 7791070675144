<template>
  <v-autocomplete
    :value="value"
    :placeholder="$t('generic.select_value')"
    :items="items"
    v-bind="{ ...defaultProps, ...$attrs }"
    :no-data-text="$t('ui.no_data')"
    @input="handleInput"
    @keydown.enter.stop
  >
    <!-- Badge in selection -->
    <template
      v-if="$attrs.badge === true"
      v-slot:selection="{ item }"
    >
      <badge
        :value="valueToInt(item.value)"
        class="mr-2"
      />
      {{ item.text }}
    </template>
    <!-- Badge in dropdown -->
    <template
      v-if="$attrs.badge === true"
      v-slot:item="{ item }"
    >
      <badge
        :value="valueToInt(item.value)"
        class="mr-3"
      />
      <v-list-item-content>
        <v-list-item-title v-text="item.text" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { isEmpty } from 'lodash';

import Badge from '../../Badge/Badge';

export default {
  name: 'SelectFilter',
  components: {
    Badge,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      defaultProps: {
        backgroundColor: 'white',
        returnObject: true,
        hideDetails: true,
        outlined: true,
        dense: true,
        clearable: true,
        badge: false,
      }
    };
  },
  methods: {
    /**
     * Aways return a value as an integer
     *
     * @param {value} - The current String or Number value
     * @returns {number}
     */
    valueToInt (value) {
      if (typeof value === 'string') {
        value = parseInt(value);
      }
      return value;
    },
    handleInput (options) {
      if (options !== undefined) {
        options = options.length > 0 ? options : options.value;
      }
      this.$emit('input', options);
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__slot .v-input__append-inner {
  padding-left: 0;
}

::v-deep .v-select__slot .v-input__append-inner .v-input__icon--clear i {
  font-size: 18px;
}
</style>

